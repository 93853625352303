<template>
  <v-card outlined class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" lg="5" class="py-4 px-4">
        <v-layout align-center class="text-sm-left" fill-height>
          <img
            class="logoPasswrd"
            v-if="logo"
            :src="serverUrl + '/images/' + logo + '?token=' + userToken"
          />
          <img
            v-else
            class="logoPasswrd"
            :src="getLogo"
            @error="failedImage = true"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" lg="7" class="py-4 px-4">
        <languageSelector
          :addContainer="true"
          :cssProps="'language-selector-in-row max-w-select-lang-login'"
        />

        <br />

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            outlined
            dense
            :label="$t('Email')"
            v-model="email"
            required
            validate-on-blur
            prepend-inner-icon="mdi-email"
            :rules="getRules('notEmptyEmailRule')"
            hide-details="auto"
          ></v-text-field>
          <v-row no-gutters class="mt-4">
            <v-btn depressed text @click="$router.go(-1)" class="mt-2">
              <v-icon left> mdi-arrow-left </v-icon>{{ $t("Cancel") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              depressed
              @click="sendResetLink()"
              :loading="loading"
              color="primary"
              class="text-right mt-2"
              >{{ $t("Send reset link") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import validation from "../../validation";

export default {
  data() {
    return {
      logo: this.$config.logoPos,

      serverUrl: this.$helpers.getServerUrl(),
      success: "",
      email: "",
      loading: false,
      userToken: this.$auth.userToken(),
      valid: true,
      failedImage: false,
    };
  },
  computed: {
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-pos.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-pos.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },
  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    sendResetLink() {
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        this.$request.post("/sendresetlink", this.email, (res) => {
          this.loading = false;

          if (res.success) {
            this.success = res.message;
            const toaster = {
              message: res.message,
              timeout: false,
              type: "SUCCESS",
              toastClassName: ["toaster-color-success", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-check-circle",
            };
            this.$store.dispatch("setToaster", toaster);
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style scoped>
.logoPasswrd {
  max-width: 100%;
  width: 90%;
  margin: auto;
}
</style>
